import { z } from 'zod';

export const assertNotNullable = <T>(value: T): NonNullable<T> => {
  if (typeof value !== 'undefined' && value !== null) return value as NonNullable<T>;

  throw new Error('Value should be defined but is not');
};

export const booleanOrUndefinedString = z
  .string()
  .optional()
  .transform(value => {
    if (value !== undefined) return value === 'true';
  });

export const booleanString = z.string().transform(value => value === 'true');

export const EmptyPaginatedSchema = z.object({
  _links: z.object({ self: z.object({ href: z.string() }) }),
  page: z.object({
    size: z.number(),
    totalElements: z.number(),
    totalPages: z.number(),
    number: z.number(),
  }),
});

const createUrjanetPaginatedSchema = <ItemType extends z.ZodTypeAny>(
  itemName: string,
  itemSchema: ItemType
) => {
  const embeds = z.object({
    _embedded: z
      .object({
        [itemName]: z.array(itemSchema),
      })
      .optional(),
  });

  const paginatedSchema = EmptyPaginatedSchema.merge(embeds);
  return paginatedSchema;
};

export const ProviderSchema = z.object({
  id: z.string(),
  name: z.string(),
  website: z.string().nullish(),
  isRealTimeCredentialValidationSupported: z.boolean(),
  isMultiFactorAuthenticationSupported: z.boolean(),
  isThirdPartyPortalSupported: z.boolean().nullish(),
});

const PageMetadata = z.object({
  size: z.number().int(),
  totalElements: z.number().int(),
  totalPages: z.number().int(),
  number: z.number().int(),
});

export const PaginatedProvidersSchema = z.object({
  page: PageMetadata,
  providers: z.array(ProviderSchema),
});

export type Provider = z.infer<typeof ProviderSchema>;
export type ProvidersResponse = z.infer<typeof ProviderSchema>;

export const OdinCredentialBaseSchema = z.object({
  allowTransientCredential: z.boolean().nullish(),
  termsOfServiceUrl: z.string().url().nullable(),
  privacyPolicyUrl: z.string().url().nullable(),
  interactive: z.boolean(),
  webhookUrl: z.string().optional(),
  correlationId: z.string().optional(),
  providerId: z.string(),
  organizationId: z.number(),
  organizationName: z.string(),
  organizationTest: z.boolean(),
  origin: z.enum(['CONNECT']),
  overrideTransientUpdate: z.boolean().nullish(),
  usernameOverride: z.string().optional(),
});

const UsernamesSchema = z.object({
  username: z.string(),
  username2: z.string().optional(),
  username3: z.string().optional(),
  username4: z.string().optional(),
});

export const PasswordsSchema = z.object({
  password: z.string().optional(),
  password2: z.string().optional(),
  password3: z.string().optional(),
  password4: z.string().optional(),
});

export const AdditionalFieldsSchema = z.object({
  correlationId: z.string().optional(),
});

export const AccountNumberSchema = z.object({ accountNumber: z.string() });
export const UsernamesAndPasswordsSchema = PasswordsSchema.merge(UsernamesSchema);

export const FormSchema = z.union([
  UsernamesAndPasswordsSchema.merge(AdditionalFieldsSchema),
  AccountNumberSchema.merge(AdditionalFieldsSchema),
]);

export const OdinCredentialUpdateSchema = OdinCredentialBaseSchema.merge(
  UsernamesAndPasswordsSchema
).merge(
  z.object({
    credentialId: z.string(),
  })
);

export const OdinCredentialSubmissionSchema = OdinCredentialBaseSchema.merge(UsernamesSchema).merge(
  UsernamesAndPasswordsSchema
);

const OdinAccountSubmissionSchema = z.object({
  accountNumber: z.string(),
  providerId: z.string(),
  correlationId: z.string().optional(),
  enabled: z.boolean().default(true),
});

export const OdinCredentialResponseSchema = z.object({
  username: z.string().nullish(),
  correlationId: z.string().nullish(),
  entityId: z.string(),
  status: z.string().optional(),
  statusDetail: z.string().optional(),
});

export type UsernamesAndPasswords = z.infer<typeof UsernamesAndPasswordsSchema>;
export type AdditionalFieldsForm = z.infer<typeof AdditionalFieldsSchema>;
export type FormFields = z.infer<typeof FormSchema>;
export type OdinCredentialBase = z.infer<typeof OdinCredentialBaseSchema>;
export type OdinCredentialUpdate = z.infer<typeof OdinCredentialUpdateSchema>;
export type OdinCredentialSubmission = z.infer<typeof OdinCredentialSubmissionSchema>;
export type OdinAccountSubmission = z.infer<typeof OdinAccountSubmissionSchema>;
export type OdinCredentialResponse = z.infer<typeof OdinCredentialResponseSchema>;

export const CredentialResponseSchema = z
  .object({
    challengeId: z.string().nullable(),
  })
  .merge(OdinCredentialResponseSchema);
export type CredentialResponse = z.infer<typeof CredentialResponseSchema>;

export const MfaVerificationMethodSchema = z.object({
  name: z.string().nullish(),
  identifier: z.string(),
  type: z.enum(['call', 'email', 'text', 'other']),
  isResubmission: z.boolean().nullish(),
});
export type MfaVerificationMethod = z.infer<typeof MfaVerificationMethodSchema>;
export const MfaVerificationMethodsSchema = z.array(MfaVerificationMethodSchema);
export type MfaVerificationMethods = z.infer<typeof MfaVerificationMethodsSchema>;

export const MfaMethodsSubmitInputSchema = z.object({
  challengeId: z.string(),
  verificationMethod: MfaVerificationMethodSchema,
});

export const MfaCodeSubmitInputSchema = z.object({
  challengeId: z.string(),
  verificationCode: z.string(),
});

export const OrganizationSchema = z.object({
  name: z.string(),
  displayName: z.string().optional(),
  uniqueId: z.string(),
  organizationType: z.string(),
  defaultUseCase: z.string(),
  privacyPolicy: z.string().url().nullable(),
  connectParentWindowAddress: z.string().nullable(),
  connectParentWindowAddressAllowLocalhost: z.boolean(),
  connectShowFinishedButtons: z.boolean().optional(),
  connectPrimaryColor: z.string().nullable(),
  connectCustomStylesheet: z.string().nullable(),
  connectHideUrjanetLogo: z.boolean().nullable(),
  connectShowBeta: z.boolean(),
  connectShowMfa: z.boolean(),
  connectShowMock: z.boolean(),
  connectShowNonRtcv: z.boolean(),
  test: z.boolean().optional(),
  entityId: z.number(),
  termsOfService: z.string().url().nullable(),
  _links: z.object({
    logo: z.object({ href: z.string() }).optional(),
  }),
});

export const PaginatedOrganizationSchema = createUrjanetPaginatedSchema(
  'organizations',
  OrganizationSchema
);

const BaseAcquistionFieldAttributes = z.object({
  attribute: z.enum([
    'LOGIN_ID',
    'LOGIN_ID2',
    'LOGIN_ID3',
    'LOGIN_ID4',
    'LOGIN_PASS',
    'LOGIN_PASS_2',
    'LOGIN_PASS_3',
    'LOGIN_PASS_4',
  ]),
});

const UsedAcquistionTemplateFields = z.object({
  attributeLabel: z.string().optional(),
  fieldNumber: z.number(),
  fieldOrder: z.number().nullish(),
  fieldLength: z.number().nullish(),
  links: z.array(z.string()).optional(),
});

const NotUsedAcquisitionTemplateSchema = z
  .object({ constraint: z.literal('NOT_USED') })
  .merge(BaseAcquistionFieldAttributes);

export const RequiredAcquisitionTemplateSchema = z
  .object({ constraint: z.literal('REQUIRED') })
  .merge(BaseAcquistionFieldAttributes)
  .merge(UsedAcquistionTemplateFields);

export type RequiredAcquisitionTemplate = z.infer<typeof RequiredAcquisitionTemplateSchema>;

export const AcquisitionTemplateFieldSchema = z.discriminatedUnion('constraint', [
  NotUsedAcquisitionTemplateSchema,
  RequiredAcquisitionTemplateSchema,
]);

export const FilteredAcquisitionTemplateSchema = z.array(RequiredAcquisitionTemplateSchema);

export const AcquisitionTemplateSchema = z.object({
  attributes: z.array(AcquisitionTemplateFieldSchema),
});

export const AcquisitionTemplateOutputFieldSchema = z.object({
  label: z.string().optional(),
  required: z.boolean(),
  attribute: z.enum([
    'password',
    'username',
    'accountNumber',
    'confirmAccountNumber',
    'correlationId',
  ]),
  specialCase: z.enum(['conEdAccountNumber']).nullish(),
  length: z.number().optional(),
  fieldOrder: z.number().nullish(),
});

export type AcquisitionTemplateOutputField = z.infer<typeof AcquisitionTemplateOutputFieldSchema>;

export const AcquisitionTemplateOutputSchema = z.array(
  z.union([z.array(AcquisitionTemplateOutputFieldSchema), AcquisitionTemplateOutputFieldSchema])
);

export type FilteredAcquisitionTemplate = z.infer<typeof FilteredAcquisitionTemplateSchema>;

export type AcquisitionTemplateField = z.infer<typeof AcquisitionTemplateFieldSchema>;
export type AcquisitionTemplate = z.infer<typeof AcquisitionTemplateSchema>;
export type AcquisitionTemplateOutput = z.infer<typeof AcquisitionTemplateOutputSchema>;

export type Organization = z.infer<typeof OrganizationSchema>;

export const TokenSchema = z.object({
  type: z.string().default('Bearer'),
  token: z.string(),
  expires: z.string(),
});

export type Token = z.infer<typeof TokenSchema>;

export const VerifiedEncodedURLSchema = z.object({
  orgId: z.string(),
  correlationId: z.string().optional(),
  createdBy: z.string().optional(),
});

export type VerifiedEncodedURL = z.infer<typeof VerifiedEncodedURLSchema>;

export const VerifiedUpdateTokenSchema = z
  .object({
    providerId: z.string(),
    credentialId: z.string(),
  })
  .merge(UsernamesAndPasswordsSchema);

export type VerifiedUpdateToken = z.infer<typeof VerifiedUpdateTokenSchema>;

export const VerifiedRefreshTokenSchema = z.object({
  credentialId: z.string(),
  providerId: z.string(),
});

export type VerifiedRefreshToken = z.infer<typeof VerifiedRefreshTokenSchema>;

export const UtilityCredentialsAccountNumberInputSchema = z.object({
  templateFields: AccountNumberSchema,
  providerId: z.string(),
  uniqueId: z.string(),
  correlationId: z.string().optional(),
});

export type UtilityCredentialsAccountNumberInput = z.infer<
  typeof UtilityCredentialsAccountNumberInputSchema
>;

export const UtilityCredentialsInputSchema = z.object({
  providerId: z.string(),
  uniqueId: z.string(),
  correlationId: z.string().optional(),
  createdBy: z.string().optional(),
  templateFields: UsernamesAndPasswordsSchema,
});

export const UtilityCredentialsUpdateInputSchema = UtilityCredentialsInputSchema.merge(
  z.object({
    credentialId: z.string(),
  })
);

export type UtilityCredentialsInput = z.infer<typeof UtilityCredentialsInputSchema>;
export type UtilityCredentialsUpdateInput = z.infer<typeof UtilityCredentialsUpdateInputSchema>;

export const apiModes = z.enum(['sandbox', 'live']);

export type ApiMode = z.infer<typeof apiModes>;

export const ProviderSearchInputSchema = z.object({
  isRealTimeCredentialValidationSupported: z.boolean().optional(),
  name: z.string(),
  apiMode: apiModes,
});

export type ProviderSearchInput = z.infer<typeof ProviderSearchInputSchema>;

export const CallbacksSchema = z.object({
  onStart: z.function().args(z.void()).returns(z.void()),
  onEnd: z
    .function()
    .args(z.object({ status: z.string() }))
    .returns(z.void()),
  onCredentialsSubmitted: z
    .function()
    .args(z.object({ utilityCredentialId: z.string() }))
    .returns(z.void()),
});

export type Callbacks = z.infer<typeof CallbacksSchema>;

const credentialId = z.string();

export const LegacyChallengeEventMFAMethodSchema = z.object({
  eventType: z.enum(['MFA_CHALLENGE_METHOD']),
  verificationMethods: z.array(MfaVerificationMethodSchema),
  credentialId,
  correlationId: z.string().nullish(),
  status: z.string().optional(),
  statusDetail: z.string().optional(),
});

export type LegacyChallengeEventMFAMethod = z.infer<typeof LegacyChallengeEventMFAMethodSchema>;

export const LegacyChallengeEventMFACodeSchema = z.object({
  eventType: z.enum(['MFA_CHALLENGE_CODE']),
  selectedVerificationMethod: MfaVerificationMethodSchema,
  credentialId,
  correlationId: z.string().nullish(),
  resubmission: z.boolean(),
});

export type LegacyChallengeEventMFACode = z.infer<typeof LegacyChallengeEventMFACodeSchema>;

export const LegacyChallengeEventLoginSchema = z.object({
  eventType: z.enum(['LOGIN_FAILURE', 'LOGIN_SUCCESS']),
  correlationId: z.string().nullish(),
  credentialId,
  status: z.string(),
  statusDetail: z.string(),
});

export type LegacyChallengeEventLogin = z.infer<typeof LegacyChallengeEventLoginSchema>;

export const ChallengeEventEmptySchema = z.object({
  eventType: z.enum(['PENDING']),
  credentialId: z.optional(credentialId),
});

export const ArcChallengeEventMFAMethodSchema = z.object({
  eventType: z.enum(['MFA_CHALLENGE_METHOD']),
  data: z.object({
    verificationMethods: z.array(MfaVerificationMethodSchema),
    credential: z.object({
      correlationId: z.string().nullish(),
      id: z.string(),
    }),
  }),
});

export const ArcChallengeEventMFACodeSchema = z.object({
  eventType: z.enum(['MFA_CHALLENGE_CODE']),
  data: z.object({
    selectedVerificationMethod: MfaVerificationMethodSchema,
    credential: z.object({
      correlationId: z.string().nullish(),
      id: z.string(),
    }),
    isResubmission: z.boolean().nullish(),
  }),
});

export const ArcChallengeEventLoginSchema = z.object({
  eventType: z.enum(['LOGIN_FAILURE', 'LOGIN_SUCCESS']),
  data: z.object({
    id: z.string(),
    correlationId: z.string().nullish(),
    status: z.string(),
    statusDetail: z.string(),
  }),
});

// Options:
// set up a z.union that doesn't use 'eventType' and can take in an empty challenge object
// stick with discriminatedUnion and add EmptySchema option
export const ChallengeSchema = z.discriminatedUnion('eventType', [
  LegacyChallengeEventMFACodeSchema,
  LegacyChallengeEventMFAMethodSchema,
  LegacyChallengeEventLoginSchema,
  ChallengeEventEmptySchema,
]);

export type Challenge = z.infer<typeof ChallengeSchema>;
